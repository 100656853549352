import { Box, BoxProps, Flex, useMultiStyleConfig } from "@chakra-ui/react";

import AlarmIcon from "./AlarmIcon";
import { ThemeTypings } from "styles/generated.theme.types";

const EaiCard = (
  props: BoxProps & {
    variant?: ThemeTypings["components"]["EaiCard"]["variants"];
    renderHeader?: () => JSX.Element;
    showIcon?: boolean;
  }
) => {
  const { variant, renderHeader, children, showIcon, ...rest } = props ?? {};
  const styles = useMultiStyleConfig("EaiCard", { variant });

  return (
    <Box __css={styles.body} {...rest}>
      {renderHeader && (
        <EaiCardHeader variant={variant} showIcon={showIcon}>
          {renderHeader()}
        </EaiCardHeader>
      )}
      {children}
    </Box>
  );
};

export default EaiCard;

export const EaiCardHeader = (
  props: BoxProps & {
    variant?: ThemeTypings["components"]["EaiCard"]["variants"];
    showIcon?: boolean;
  }
) => {
  const { variant, showIcon, ...rest } = props;
  const { header } = useMultiStyleConfig("EaiCard", {
    variant: variant,
  });

  const Icon = AlarmIcon;

  return (
    <Flex
      flex="1"
      alignItems="center"
      fontWeight="bold"
      {...rest}
      as={Flex}
      sx={header}
    >
      {showIcon && <Icon boxSize={6} mr="4" />}
      {props.children}
    </Flex>
  );
};
