import { AlertProps, createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { alertAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props;

  const infoBase = status === "info" && {
    container: {
      background: "blue.50",
    },
  };

  return {
    ...infoBase,
  };
});

const Alert = defineMultiStyleConfig({ baseStyle });

export default Alert;
